<template>
	<div class="ocr">
		<!-- <div class="content" v-if="false">
      <div class="header">
        <div
          class="btnIcon"
          v-for="(i, index) in iconList"
          :key="index"
          @click="getBtnIcon(index)"
        >
          <i :class="i.class"></i>
          <p>{{ i.text }}</p>
        </div>
      </div>
      <div class="center">
        <div class="center-left">
          <div
            class="left-img"
            v-loading="loading"
            element-loading-text="正在获取中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
          >
            <i
              class="iconfont icon-shanchu"
              v-show="picBase64"
              @click="delImgSrc"
            ></i>
            <div v-viewer>
              <img v-show="picBase64" :src="picBase64" alt="图片" />
            </div>
            <img v-show="!picBase64" :src="pic" alt="默认" />
          </div>
          <div class="upload">
            <el-row class="lawMain" type="flex" justify="space-around">
              <el-col :span="16">
                <span class="upload-text"
                  >图片类型仅支持支持 {{ imgType }}大小不超过{{
                    imgMaxSize
                  }}M</span
                >
              </el-col>
              <el-col :span="4">
                <el-button
                  class="upload-del"
                  size="small"
                  type="info"
                  @click="clearAll"
                  >清空页面</el-button
                >
              </el-col>
              <el-col :span="4">
                <label class="upload-btn" size="small" type="primary"
                  >点击上传
                  <input
                    class="upload-submit"
                    type="file"
                    name="image"
                    @change="getImgUrl"
                  />
                </label>
              </el-col>
            </el-row>
          </div>
          <div class="left-imgList">
            <div
              class="left-imgList-item"
              v-for="(item, index) in oldFileList"
              :class="{ active: isShow == index }"
              :key="index"
              @click="onImgItem(index)"
            >
              <img :src="item.inputData" :alt="item.id" />
            </div>
          </div>
        </div>
        <div class="center-right">
          <div class="right-result">
            <i
              class="iconfont icon-fuzhi right-result-copy"
              v-if="resultList.length"
              @click="textCopy()"
              >复制</i
            >
            <div class="right-result-head">
              <i class="iconfont icon-shibie"><span>识别结果</span></i>
            </div>
            <div class="right-result-content">
              <div v-for="(item, index) in resultList" :key="index">
                <div
                  class="right-result-content-data"
                  v-show="item.type == 0 || item.type == 1"
                >
                  <p><span>出发地：</span>{{ item.startStation }}</p>
                  <p><span>到达地：</span>{{ item.endStation }}</p>
                  <p><span>日期：</span>{{ item.time }}</p>
                  <p><span>乘客：</span>{{ item.name }}</p>
                  <p><span>席别：</span>{{ item.seat }}</p>
                  <p><span>发票金额：</span>{{ item.money }}</p>
                  <p><span>票据号码：</span>{{ item.num }}</p>
                </div>
                <div class="right-result-content-data" v-show="item.type == 2">
                  <p><span>销售方单位：</span>{{ item.seller }}</p>
                  <p><span>销售方地址：</span>{{ item.addr }}</p>
                  <p><span>发票金额：</span>{{ item.money }}</p>
                  <p><span>票据号码：</span>{{ item.num }}</p>
                  <p><span>内容：</span>{{ item.content }}</p>
                </div>
                <div class="right-result-content-data" v-show="item.type == 3">
                  <p><span>来问标题：</span>{{ item.title }}</p>
                  <p><span>来问字号：</span>{{ item.fontNum }}</p>
                  <p>
                    <span>来文单位：</span>{{ JSON.stringify(item.company) }}
                  </p>
                </div>
                <div class="right-result-content-data" v-show="item.type == 4">
                  <p v-show="!item.ocrResult">识别失败</p>
                  <p v-show="item.ocrResult">
                    <span>识别结果：</span>{{ item.ocrResult }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
		<div class="content">
			<div class="upgrade">
				<img v-lazy="sjImg" alt="升级中" />
				<span>敬请期待</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ocr',
	data() {
		return {
			sjImg: window.imgUrl + '/10_sj.png',
			activeName: 'first',
			isSrcPdf: false, //当前这个文件是否是pdf
			loading: false, //加载中
			titleBase64: '', //base64头部
			articles: [],
			isShow: 0,
			pic: '', //默认为空的图片地址
			type: 0, //右侧识别后票据类型，默认为火车票
			format: '',
			imgId: 0,
			file: '',
			picBase64: '',
			newPicBase64: '',
			oldFileList: [], //历史图片的参数，用于展示图片
			fileList: [], //图片的各项参数，=msg,
			resultList: [], //返回的票据信息
			oldResultList: [], //存储历史，返回的票据信息
			iconList: [
				{
					id: 0,
					class: 'iconfont icon-piaoju_shouju',
					text: '票据识别',
					index: 1,
				},
				{
					id: 1,
					class: 'iconfont icon-zhengjian1',
					text: '证照识别',
					index: 1,
				},
				{
					id: 2,
					class: 'iconfont icon-yonghuzhongxin',
					text: '名片识别',
					index: 1,
				},
				{
					id: 3,
					class: 'iconfont icon-yinhangka',
					text: '银行卡识别',
					index: 1,
				},
				{
					id: 4,
					class: 'iconfont icon-KHCFDC_wenben',
					text: '文本识别',
					index: 1,
				},
			],
			imgType: '', //图片允许的格式
			imgMaxSize: '', //图片允许的文件最大值
		}
	},
	watch: {},

	mounted() {
		// for (var key of window.fileTypes) {
		//   this.imgType = this.imgType + key + "，";
		// }
		// this.imgMaxSize = window.fileMaxSize / 1024;
		// this.delImgSrc();
		// let btnIconAll = document.querySelectorAll(".btnIcon");
		// btnIconAll[0].classList.add("btnIconActive");
	},
	methods: {
		getlocal() {
			//找到锚点id
			let toElement = document.getElementById('experienceId')
			toElement.scrollIntoView()
		},
		getBtnIcon(index) {
			this.type = index
			let btnIconAll = document.querySelectorAll('.btnIcon')
			btnIconAll.forEach((item) => {
				item.classList.remove('btnIconActive')
			})
			btnIconAll[index].classList.add('btnIconActive')
		},
		// 获取图片base64
		getImgUrl(event) {
			let that = this
			this.uploadFiles(event.target)
			this.verificationPicFile(event.target)
			this.imgId = this.imgId + event.target.id + 1
			let imgList = event.target.files
			let dataList = []
			//  for(var i=0;i < imgList.length;i++){
			that.file = imgList[0]
			var reader = new FileReader()
			reader.readAsDataURL(this.file)
			reader.onload = () => {
				let ImgBase64 = reader.result
				// 实现预览，实际是拿到图片的base64数据去挂在到图片的src上
				that.picBase64 = ImgBase64

				let imgFile = {
					format: that.format, //图片类型 0-jpg
					inputData: ImgBase64,
					type: parseInt(that.type), //票据类型
					id: that.imgId, //图片id
				}
				that.oldFileList = [{ ...imgFile }, ...that.oldFileList] //存放历史，上传的图片信息

				dataList.push(imgFile)
				that.fileList = dataList

				this.uploadImage()
				document.getElementsByClassName('upload-submit')[0].value = null
			}
			// }
			return false
		},
		// 上传图片
		uploadImage() {
			this.loading = true
			let taskId = this.createUniqueId()
			let ImgBase64 = this.fileList[0].inputData
			if (ImgBase64) {
				this.titleBase64 = ImgBase64.substring(
					0,
					ImgBase64.indexOf('base64') + 7
				)
				ImgBase64 = ImgBase64.substring(
					ImgBase64.indexOf('base64') + 7,
					ImgBase64.length
				)
			}
			this.fileList[0].inputData = ImgBase64
			let data = {
				msg: this.fileList,
				taskId, //任务id
			}
			this.axios({
				url: window.homeUrl,
				method: 'post',
				timeout: 10000,
				data: JSON.stringify(data),
			})
				.then((res) => {
					if (res.data.errno === 0) {
						this.loading = false
						this.$message.success(res.data.errMsg)
						let result = res.data.result
						result[0].imageData =
							this.titleBase64 + result[0].imageData
						this.picBase64 = result[0].imageData

						this.oldFileList[0].inputData = this.isSrcPdf
							? result[0].imageData
							: this.oldFileList[0].inputData //当前上传的是pdf文件，就获取后端返回的图片地址

						result[0].type = this.type //在返回的数据中增加 变量数据类型
						this.resultList = result //返回数据，在右侧展示
						if (
							(this.type == 0 && !result[0].startStation) ||
							(this.type == 1 && !result[0].startStation) ||
							(this.type == 2 && !result[0].seller) ||
							(this.type == 3 && !result[0].fontNum) ||
							(this.type == 4 && !result[0].ocrResult)
						) {
							this.$message.warning(
								'识别错误，请重新确认图片类型'
							)
						}
						this.isShow = 0 //上传成功，默认选中第一个
						this.oldResultList = [...result, ...this.oldResultList] //存放历史识别结果
					} else {
						this.$message.warning('识别失败')
					}
				})
				.catch((err) => {
					this.$message.warning('接口错误', err)
					this.loading = false
				})
		},

		//验证上传文件的格式
		uploadFiles(file) {
			var fileTypes = window.fileTypes
			var filePath = file.value //返回的是上传文件的路径名 例如：E:\xx\xxx.jpg
			//当括号里面的值为0、空字符、false 、null 、undefined的时候就相当于false
			if (filePath) {
				var isNext = false
				var fileEnd = filePath.substring(filePath.indexOf('.')) //截取文件名的后缀
				if (fileEnd == '.pdf') this.isSrcPdf = true
				for (var i = 0; i < fileTypes.length; i++) {
					if (fileTypes[i] == fileEnd) {
						isNext = true
						this.format = i
						break
					}
				}
				if (!isNext) {
					this.$message.warning('不接受此文件类型')
					document.querySelector('upload-submit').value = '' //如果文件后缀名不符合，清空已选择文件
					return false
				}
			} else {
				return false
			}
		},
		//验证上传文件的大小
		verificationPicFile(file) {
			var fileSize = 0
			var fileMaxSize = window.fileMaxSize //2M
			var fileMinSize = window.fileMinSize
			var filePath = file.value
			if (filePath) {
				fileSize = file.files[0].size
				var size = fileSize / 1024
				if (size > fileMaxSize) {
					this.$message.warning(
						`图片大小不能大于${Math.ceil(fileMaxSize / 1024)}M！`
					)
					file.value = ''
					return false
				} else if (size <= fileMinSize) {
					this.$message.warning(`图片大小不能小于${fileMinSize}KB！`)
					file.value = ''
					return false
				}
			} else {
				return false
			}
		},
		//点击历史图片，更改识别结果
		onImgItem(index) {
			this.isShow = index
			const resultListAll = this.oldResultList
			const newResult = resultListAll[index]
			this.picBase64 = newResult.imageData
				? newResult.imageData
				: this.delImgSrc()
			this.type = newResult.type
			this.resultList = [newResult]
		},

		//点击复制按钮，复制文本内容
		textCopy() {
			// this.copyContent = '用户管理';//也可以直接写上等于你想要复制的内容
			let content = document.querySelector('.right-result-content')
			this.copyContent = content.innerText
			var input = document.createElement('input') // 直接构建input
			input.value = this.copyContent // 设置内容
			document.body.appendChild(input) // 添加临时实例
			input.select() // 选择实例内容
			document.execCommand('Copy') // 执行复制
			document.body.removeChild(input) // 删除临时实例
			this.$message.success('内容已复制到剪切板！')
		},
		//随机生成一个唯一id
		createUniqueId(n) {
			n = 1 // 生成一个唯一id，包含数字和字母
			var random = function () {
				// 生成10-12位不等的字符串
				return Number(Math.random().toString().substr(2)).toString(36) // 转换成十六进制
			}
			var arr = []
			function createId() {
				var num = random()
				var _bool = false
				arr.forEach((v) => {
					if (v === num) _bool = true
				})
				if (_bool) {
					createId()
				} else {
					arr.push(num)
				}
			}
			var i = 0
			while (i < n) {
				createId()
				i++
			}
			let uniqueId = arr[0] // 将生成的转为我们需要的字符串并赋值
			return uniqueId
		},

		//初始打开，默认照片
		delImgSrc() {
			this.loading = false
			this.picBase64 = ''
			this.pic = window.imgUrl + '/dianjishangchuan.png'
		},
		//清空页面
		clearAll() {
			this.delImgSrc()
			let content = document.querySelector('.right-result-content')
			content.innerText = ''
			this.oldFileList = []
			document.getElementsByClassName('upload-submit')[0].value = null
		},
	},
	created() {
		//创建时执行跳转锚点位置
		this.$nextTick(() => {
			this.getlocal()
		})
	},
	components: {},
	computed: {},
	destroyed() {},
}
</script>
<style lang="scss" scoped>
.ocr {
	scroll-padding-top: 50vh;

	.content {
		width: 100%;
		min-height: 3rem;
		.header {
			width: 100%;
			height: 1rem;
			padding: 0 10%;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #333333;
			margin-bottom: 0.4rem;
			.btnIcon {
				width: 20%;
				height: 100%;
				border-radius: 0.1rem 0.1rem 0 0;
				cursor: pointer;
				i {
					font-size: 0.4rem;
					margin-top: 0.1rem;
				}
				// i:nth-child(2){
				//   font-size: .6rem;
				// }
				p {
					font-size: 0.18rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: bold;
					line-height: 130%;
				}
				&:hover {
					background-color: #fff;
					color: #127bf2;
				}
			}
		}
		.center {
			width: 100%;
			min-height: 6rem;
			display: flex;
			justify-content: center;
			align-items: center;
			// flex-wrap: wrap;
		}
		.center-left {
			width: 6.4rem;
			height: 8.5rem;
			.left-img {
				width: 100%;
				min-height: 3rem;
				height: 52%;
				border: 1px #ccc dashed;
				overflow: hidden;
				position: relative;
				i {
					width: 0.25rem;
					height: 0.25rem;
					display: block;
					line-height: 170%;
					font-size: 0.14rem;
					position: absolute;
					right: 0;
					top: 0;
					background-color: #000;
					color: #fff;
					opacity: 0.7;
					border-radius: 0 0 0 60%;
					z-index: 2;
					&:hover {
						background-color: #127bf2;
					}
				}
				img {
					max-width: 100%;
					max-height: 100%;
					width: auto;
					height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate3d(-50%, -50%, 0);
					-webkit-transform: translate3d(-50%, -50%, 0);
				}
			}
			.upload {
				width: 100%;
				height: 0.5rem;
				// display: flex;
				// justify-content: space-around;
				// align-items: center;
				margin: 0.1rem 0;
				background-color: rgba(0, 0, 0, 0.4);
				font-size: 0.12rem;
				.lawMain {
					height: 100%;
					align-items: center;
				}
				.upload-text {
					font-size: 0.12rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #ffffff;
					line-height: 166%;
				}
				.upload-select {
					width: 1.2rem;
					height: 0.3rem;

					&.el-input {
						height: 0.3rem;
					}
					&.el-input__inner {
						height: 100%;
					}
					&.el-input__icon {
						line-height: 1;
					}
				}
				.upload-del {
					width: 0.8rem;
					min-width: 0.6rem;
					height: 0.3rem;
					font-size: 0.12rem;
					line-height: 0;
					margin: 0 0.05rem;
					background: #555;
					border-radius: 0.05rem;
				}
				.upload-btn {
					width: 0.8rem;
					min-width: 60px;
					height: 0.3rem;
					font-size: 0.12rem;
					line-height: 0.3rem;
					border-radius: 0.05rem;
					padding: 0 0.05rem;
					margin-right: 0.05rem;
					color: #fff;
					background: #127bf2;
					display: inline-block;
					cursor: pointer;
					box-sizing: border-box;
				}
				.upload-submit {
					display: none;
				}
			}
			.left-imgList {
				width: 100%;
				min-height: 1.42rem;
				height: 25%;
				display: flex;
				overflow-x: scroll;
				overflow-y: hidden;
				border: 1px #ccc dashed;
				.active {
					border: solid 1px #0069d1;
				}
				.left-imgList-item {
					min-width: 1.2rem;
					width: 30%;
					min-height: 1.2rem;
					height: 85%;
					margin-right: 5%;
					background-color: aliceblue;
					position: relative;
					img {
						max-width: 100%;
						max-height: 100%;
						width: auto;
						height: auto;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate3d(-50%, -50%, 0);
						-webkit-transform: translate3d(-50%, -50%, 0);
					}
				}
			}
		}
		.center-right {
			width: 6.4rem;
			height: 8.5rem;
			.right-result {
				background-color: #ffffff;
				position: relative;
				min-height: 5rem;
				height: 85%;
				.right-result-copy {
					float: right;
					position: relative;
					right: 0.15rem;
					top: 0.1rem;
					color: #fff;
					width: 0.8rem;
					font-size: 0.14rem;
					display: block;
					z-index: 2;
					cursor: pointer;
					&:hover {
						color: #127bf2;
					}
				}
				.right-result-head {
					width: 100%;
					height: 0.36rem;
					background-color: #999;
					color: #fff;
					font-size: 0.14rem;
					i {
						height: 0.36rem;
						font-size: 0.16rem;
						line-height: 2;
						padding-left: 0.15rem;
						float: left;
						span {
							margin-left: 0.05rem;
						}
					}
				}
				.right-result-content {
					width: 100%;
					background-color: #f2f2f2;
					min-height: 4.8rem;
					height: 96%;
					overflow-x: hidden;
					overflow-y: auto;
					.right-result-content-data {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
						padding-left: 0.2rem;
						overflow-x: hidden;
						p {
							line-height: 0.16rem;
							word-wrap: break-word;
							word-break: break-all;
							text-align: start;
							span {
								display: block;
								float: left;
							}
						}
					}
				}
			}
		}

		.upgrade {
			width: 8rem;
			height: 8rem;
			// text-align: center;
			margin: auto;
			position: relative;
			img {
				width: 100%;
				height: 100%;
				display: block;
			}
			span {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0.46rem;
				font-size: 0.24rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 500;
				color: #999999;
				line-height: 1;
				text-align: center;
			}
		}
	}
}
.btnIconActive {
	background-color: #fff;
	color: #127bf2;
}
@media screen and (max-width: 1200px) {
	.ocr .content {
		.center-left {
			width: 8.6rem;
		}
		.center-right {
			width: 8.6rem;
		}
	}
}
</style>
